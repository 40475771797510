/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, provide us the comprehensive description what you have experienced. It will help us to find exact problem and fix it."])},
        "report-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue"])},
        "your-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report"])},
        "your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the issue"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while sending the issue"])}
      }
    }
  })
}
