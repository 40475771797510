import { defineComponent } from 'vue';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
import BugReport from '@/components/BugReport/BugReport.vue';
export default defineComponent({
    name: 'App',
    components: {
        BugReport
    }
});
