import { defineComponent } from 'vue';
import { CloseBold } from '@element-plus/icons-vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/common-store/user';
import request from '@/utils/request';
import { ElMessageBox } from 'element-plus';
export default defineComponent({
    components: {
        CloseBold
    },
    data: () => ({
        opened: false,
        reporterEmail: '',
        report: '',
        sending: false
    }),
    computed: {
        ...mapStores(useUserStore)
    },
    methods: {
        close() {
            this.opened = false;
        },
        open() {
            this.opened = true;
            this.userStore.getCurrentUser();
        },
        send() {
            this.sending = true;
            request('/api/issue-report', {
                method: 'put',
                body: JSON.stringify({
                    email: this.reporterEmail,
                    actualEmail: this.userStore.userInfo.email,
                    report: this.report,
                    url: this.$route.fullPath
                })
            }).then(({ errors }) => {
                this.sending = false;
                if (!errors.length) {
                    this.opened = false;
                }
                else {
                    ElMessageBox.alert(errors[0], this.$t('error'), { confirmButtonText: this.$t('close') });
                }
            });
        }
    }
});
