import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CloseBold = _resolveComponent("CloseBold");
    const _component_el_icon = _resolveComponent("el-icon");
    const _component_el_button = _resolveComponent("el-button");
    const _component_el_input = _resolveComponent("el-input");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("button", {
            class: _normalizeClass(_ctx.$style.button),
            type: "button",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.open && _ctx.open(...args)))
        }, " 🐞 ", 2),
        (_ctx.opened)
            ? (_openBlock(), _createElementBlock("section", {
                key: 0,
                class: _normalizeClass(_ctx.$style.form)
            }, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('report-heading')), 1),
                _createVNode(_component_el_button, {
                    class: _normalizeClass(_ctx.$style.close),
                    link: "",
                    onClick: _ctx.close
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_CloseBold)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["class", "onClick"]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style.label)
                }, _toDisplayString(_ctx.$t('your-email')), 3),
                _createVNode(_component_el_input, {
                    modelValue: _ctx.reporterEmail,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.reporterEmail) = $event)),
                    type: "email",
                    placeholder: _ctx.$t('email')
                }, null, 8, ["modelValue", "placeholder"]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style.label)
                }, _toDisplayString(_ctx.$t('your-report')), 3),
                _createVNode(_component_el_input, {
                    modelValue: _ctx.report,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.report) = $event)),
                    placeholder: _ctx.$t('describe'),
                    type: "textarea",
                    rows: "9"
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_el_button, {
                    class: _normalizeClass(_ctx.$style.sendButton),
                    type: "primary",
                    loading: _ctx.sending,
                    onClick: _ctx.send
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('send')), 1)
                    ]),
                    _: 1
                }, 8, ["class", "loading", "onClick"])
            ], 2))
            : _createCommentVNode("", true)
    ], 64));
}
